
            @import "@/assets/css/vars.scss";
          



















































































































































































































































































































































































































































































































































































































































































































#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      // background-color:  var(--btn-hover)!important;
      filter: brightness(0.90) !important;
      // filter: drop-shadow(0px 1px 3px #DFDFE8);
}
.single-blog-wrapper {
  .baner {
      position: relative;
      // top: 25px;
      top: 56px
      // top : calc(198px + 56px); // 198px->blog details 56px->margin on design
    }
}
