:root {
    --white-background-color: #FFFFFF;
    --background-color: #f9f8f8;
    --main-color: #FF5654;
    /* --btn-color: #FF6359; */
    --btn-color-transparent: #ff4c495a;
    --btn-hover: #FF4B49;
    --text-lighter: #9498A0;
    --text-darker: #0D1015;
    --green-color: #395163;
    --green-darker: #062931;
    --green-text: #2c3e50;
    --border-color: #F3EEEE;
    --input-border: #DFDFE8;


    --err-color: #FF4B49;

    /*background of: all page, sub-comments, input-fealds, btn-txt */
    --main-background-color: #061325;
    /* header, footer : background-color */
    --header-background-color: #091628;
    /* modal content : background-color */
    --login-modal-background-color: #091628;
    /* main comments color, border to sub-comments*/
    --comments-background-color: #18181e;
    /* link, button, highlighted txt */
    --btn-color: #F7FB51;
    /* title, sub-title, name-section */
    --title-color: #FFFFFF;
    /* all text color, input text color, comments text color */
    --text-color: #ffffff;
    /* dates in comments, view more, reply */
    --text-soft-color: #9498A0;
    /* all border color */
    --border-color: #ffffff2b;
    /* header, footer : text-color */
    --header-text-color: #FFF;



}