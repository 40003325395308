
            @import "@/assets/css/vars.scss";
          






























































































































































































































































































































.localePickerAdmin{
  background: var(--main-background-color);
  width: 65px;
  height: 150px;
  color: #5A7E87;
  .picker-wrapper {
    height: fit-content;
    display: flex;
    justify-items: flex-start;
    align-items: flex-start;
    color: #5A7E87;
  .picker-title{
    color: #5A7E87;
  }
  }
}
