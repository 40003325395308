
            @import "@/assets/css/vars.scss";
          
































































































































































a{
  text-decoration: none;
}
a:link {
  color: var(--text-color);
}
a:visited  {
  color: var(--text-color);
}
a:hover  {
  color: var(--text-color);
}
.footer-wrap {
  position: relative;
  z-index: 20;
  padding: 60px 30px 0 30px;
  background-color: var(--header-background-color);

  .footer {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      a {
        display: grid;
        place-items: center;
      }

      .logo-grp {
        img {
          width: 55px;
        }

        span {
          margin-left: 15px;
        }
      }

      .top-grp {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;

        .icon {
          margin-right: 15px;
        }

        .icon-soc {
          margin-left: 30px;
        }
      }
    }
  }

  .bot {
    border-top: 1px solid #ffffff2b;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 30px;
    color: #ffffff80;

    .bot-logo {
      height: 24px;
    }
  }

  .bot-mobile {
    border-top: 1px solid #ffffff2b;
    color: #ffffff80;
    max-width: 400px;
    padding: 20px 0;
    display: none;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;

    #rights {
      white-space: nowrap;
      margin: 10px 0;
    }

    .enon,
    .mars {
      display: flex;
      justify-content: space-between;

      /* stylelint-disable-next-line no-descending-specificity */
      .marslogo {
        height: 18px;
        top: 3px;
      }

      .enonlogo {
        height: 30px;
      }
    }
  }
}

@media only screen and (width <= 1000px) {
  .footer-wrap {
    .footer {
      .top {
        flex-direction: column;

        .logo-grp {
          height: 100px;

          .logo-text {
            display: none;
            height: 80px;
          }

          img {
            height: 100%;
            width: 100%;
          }

          .desktop {
            display: none;
          }
        }

        .top-grp {
          justify-content: center;
          margin-bottom: 20px;
          align-items: center;

          .icon {
            margin-right: 10px;
          }

          .icon-soc {
            margin-left: 20px;
          }
        }
      }

      .bot {
        display: none;
      }

      .bot-mobile {
        display: flex;
      }
    }
  }
}
