
            @import "@/assets/css/vars.scss";
          
















































































































































































































































































































































































































































































































































































.middle{ 
    .wrpLocale{
      // scale: 1.2 !important;
      transform: scale(1.3);

  }
}

