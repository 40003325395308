
            @import "@/assets/css/vars.scss";
          


























































































































































































@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
  margin:0;
}
#app {
  //  font-family: Avenir, Helvetica, Arial, sans-serif;
  //  font-family: "Oswald", sans-serif;
  font-family: 'Archivo';
  // font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: var(--text-color);
  background: var(--main-background-color);
}
.backgroundImg{
  // background-image: url("./assets/images/background.png");
  //   background-repeat: no-repeat;
   
  //   background-attachment: fixed;
  //   // background-size: 100%  ;
  //   background-size: cover;
  //   background-position: center;
  //   // background-size: 100% 120%;
}
.backgroundGradient{
  background: linear-gradient(114.92deg, #011a59 0%, var(--main-background-color) 51.56%, #011a59 100%);
}

.nested-enter-active, .nested-leave-active {
	transition: all 0.9s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.65s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--text-color);

    &.router-link-exact-active {
      color: var(--text-color);
    }
  }
}
