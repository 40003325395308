
            @import "@/assets/css/vars.scss";
          









































.go-top-wrapper {
   z-index: 2000;
   position:relative;
 
  @keyframes pulse {
      0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 var(--btn-color);
      }

      70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
  }
  .wrapImgTop{
    height: 42px;
    width: 42px;
    border-radius: 50%;
    position: fixed;
    bottom: 80px;
    // right: 30px;
    // right: 20px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 0 0 var(--btn-color);
    transform: scale(1);
    animation: pulse 2s infinite;
    background: var(--btn-color);
  }
  .img {
    background: var(--btn-color);
    cursor: pointer;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    z-index: 1001;
   
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      // width: 38px;
      // height: 38px;
    }
    svg{
      path{
        stroke: var(--main-background-color);
      }
    }
  }
  .img:hover {
    filter: brightness(0.96);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition-duration: .9s;
    z-index: 1001;
  }
}
@media only screen and (max-width: 768px){
  .go-top-wrapper {
  }
}
