@import '@/assets/css/buttonStyle.scss';

input,
textarea {
    background: var(--main-background-color);
}

input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=password] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    letter-spacing: 5px;
}

.buttonSocial {
    box-sizing: border-box;
    width: 406px;
    height: 50px;
    background: var(--main-background-color);
    border: 1px solid transparent;
    padding: 0 18px 0 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
    border-radius: 5px;

    /*text*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--text-color);

}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: var(--text-color);
    display: inline-block;
    margin-bottom: 6px;
}

.basic,
.basicTextarea {
    box-sizing: border-box;
    // border: 1px solid #C3C6D0;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
    // border-radius: 5px;
    padding-left: 10px;
    outline: none;

    height: 39.48px;
    border: 1px solid var(--border-color);
    // filter: drop-shadow(0px 1px 3px var(--border-color));
    border-radius: 6px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* black */

    color: var(--text-color); // var(--green-darker);
}

.basicTextarea {
    height: 100px;
    // width: 406px;
    // padding-top: 12px;
    // padding-top: 15px;
    padding-top: 13px;
}

input::placeholder,
textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--text-soft-color);
    // padding-left: 10px;
    letter-spacing: 0.01em;
}

.basic:focus {
    border: 1px solid var(--text-soft-color);
}

.or {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    color: var(--title-color);
    padding-top: calc(12px + 5px); // + 15px margin from input field = 32px space 
    padding-bottom: calc(29px + 4px);
    margin: 0;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line {
        // width: 186px;
        width: 180px;
        height: 0;
        border-top: 1px solid var(--border-color);
    }
}



// .buttonSend {
//     cursor: pointer;
//     width: 100%;
//     height: 50px;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 24px;
//     background: var(--btn-color);
//     border-radius: 5px;

//     display: flex;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     color: #FFFFFF;

// }

// .buttonSend:hover {
//     background-color: var(--btn-hover);
// }

// .haveaccount {
//     margin-top: 20px;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 25px;
//     color: var(--green-darker);

// }

// .orange {
//     font-style: normal;
//     font-weight: normal;
//     font-size: 15px;
//     line-height: 25px;
//     cursor: pointer;
//     color: var(--btn-color);
//     text-decoration: none;
// }

.inputErr,
.textareaErr {
    border: 1px solid var(--err-color);
    box-sizing: border-box;
    border-radius: 5px;
}

.errMsg {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--err-color);

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    //   border: 1px solid green;
    -webkit-text-fill-color: var(--text-soft-color);
    -webkit-box-shadow: 0 0 0px 1000px var(--main-background-color) inset;
    transition: background-color 5000s ease-in-out 0s;
    // background-color: var(--main-background-color) !important;
}


@media only screen and (max-width: 500px) {
    .buttonSocial {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px;

        /*text*/
        font-size: 14px;
        line-height: 24px;
    }

    .basic,
    .basicTextarea {
        width: 100%;
        height: 50px;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 24px;
        // color: #132727;


    }

    .basicTextarea {
        height: 80px;
    }

    input::placeholder {
        font-size: 14px;
        line-height: 24px;
    }

    .or {
        padding-top: 7px; // 15 px margin from input field // 22 
        padding-bottom: 23px;
        margin: 0;
        text-align: center;

        .line {
            width: 150px;
        }
    }



    // .buttonSend {
    //     cursor: pointer;
    //     width: 100%;
    //     height: 50px;
    //     font-size: 15px;
    //     line-height: 24px;
    // }

    .haveaccount {
        font-size: 14px;
        line-height: 25px;
        margin-top: 34px;
    }

    // .orange {
    //     font-size: 14px;
    //     line-height: 25px;
    // }



}