
            @import "@/assets/css/vars.scss";
          






























































































































































































































































































































































































































































































@import '@/assets/css/adminBlogForm';
.colorBTN{
  color: var(--btn-color);
}

