
            @import "@/assets/css/vars.scss";
          

























































































































































































































































































    .loginComponent{
        .basic:last-of-type{
            margin-bottom: 10px;
        }
        .rember {
            margin-top: 24px;
            margin-bottom: 32px;
        }
    }
@media only screen and (max-width: 500px){
    .loginComponent{
        // .basic:last-of-type{
        //     margin-bottom: 10px;
        // }
        .rember {
            margin-top: 26px;
            margin-bottom: 28px;
        }
    }  
}

