
            @import "@/assets/css/vars.scss";
          






































































































































































































































































































































































.wrapp {
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
    // border-bottom: 1px solid var(--border-color) ;
}



.mainComment {
    //  max-width:100%;
    //  width: fit-content;
    width: 100%;
    min-height: 80px;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--comments-background-color);
    // background: #ECECEC;
    border-radius: 25px;
    padding: 18px 17px;
    transition-duration: height .3s;
    filter: brightness(1);

    .wrappMaindata {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        font-weight: bold;

        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: var(--title-color);
        }

        .date {
            margin-left: 30px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            /* identical to box height, or 167% */

            letter-spacing: 0.04em;
            color: var(--text-soft-color);

        }
    }



}

.more {
    margin-left: 13px;
    border-left: 1px solid var(--comments-background-color);
    background-color: var(--main-background-color);


    .option {
        padding-left: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: var(--text-lighter);
        margin-bottom: 5px;
        cursor: pointer;
    }

    .wrappSubComm {
        display: flex;
        flex-direction: column;
        transition: height .3s;

    }

    .wrappSubComm {
        padding-top: 25px;


        .subComment {
            border-radius: 5px;
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            padding-left: 0;
            padding-right: 17px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: var(--main-background-color);


            .line {
                box-sizing: border-box;
                width: 50px;
                height: 0;
                border-top: 1px solid var(--comments-background-color);
            }

            .wrappData {
                width: calc(100% - 50px);
                // padding-left: 22px;
                background-color: var(--main-background-color);
                position: relative;
                z-index: 10;
                filter: brightness(1);

                .wrappData {
                    width: calc(100%);
                    box-sizing: border-box;
                    padding: 14px 18px 0 18px;
                    background-color: var(--main-background-color);
                    position: relative;
                    z-index: 10;
                    filter: brightness(1);
                    border-radius: 25px;
                }
            }

            .wrappMaindata {
                display: flex;
                justify-content: space-between;
                // margin-bottom: 10px;
                font-weight: bold;

                .name {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 17px;
                    color: var(--text-color);
                }

                .date {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height, or 167% */

                    letter-spacing: 0.04em;
                    color: var(--text-soft-color);

                }
            }
        }
    }
}

.more2 {
    border-left: 1px solid transparent;
    background-color: var(--main-background-color);
    // height: 237px ;
    // transition: height .3s;
    overflow: hidden;
    // 50px->line, 22px->padding from line to content, 13px->margin of more 
    margin-left: calc(50px + 22px + 13px);
}

.wrappStatus {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 10px;
    // font-weight: bold;

    // background-color: var(--btn-color);
    background-color: #36714a;
    filter: brightness(2.8);
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0px;
    box-sizing: border-box;
    padding: 3px 27px;
    padding-bottom: 25px;
    border-radius: 25px 25px 0 0;
    z-index: 0;
   

    .name {
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-color);
        color: var(--main-background-color)!important;

        span {
            font-weight: 700;
        }
    }
    .blgName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 50%; 
        justify-self: end;
        font-weight: 900;
        font-size: 14px;
        line-height: 17px;
        color: var(--text-color);
        color: var(--main-background-color)!important;
    }

    .date {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        letter-spacing: 0.04em;
        color: var(--text-color);
        color: var(--main-background-color)!important;

    }

}

.parent {
    filter: brightness(.85);
}

.wrappComm {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.wrappIcon {
    display: flex;
    align-items: center;
}
