
            @import "@/assets/css/vars.scss";
          
















































































































































a {
  text-decoration: none;
}
.blog-item-wrapper {
  display: flex;
  flex-direction: row;
  // max-width: 842px; 
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 35px;
  padding-top: 50px;
  align-items: flex-start;
  cursor: pointer;

  .aspect-wrapper {
    // max-width: 358px;
    width: 33%;
    margin-right: 25px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 5px;
    .aspWrap{
      overflow: hidden;
      border-radius: 5px;
      will-change: transform;
      position: relative;
      z-index: 100;
    }
    .aspect {
      padding-top: 63%;
      position: relative;
      height: 0;

      .img-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        will-change: transform;
        background: var(--comments-background-color);;

        img {
          z-index: 5;
          height: 100%;
          object-fit: cover;
          width: auto;
          border-radius: 5px;
          transition: 1s ease-in-out;
          -webkit-backface-visibility: hidden;
          -ms-transform: translateZ(0); /* IE 9 */
          -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
          transform: translateZ(0);
          -webkit-font-smoothing: antialised;
          backface-visibility: hidden;
          -webkit-font-smoothing: subpixel-antialiased;
          filter: blur(0);
        }
      }

      img:hover {
        // height: 115%;
        // widows: 115%;
         transform: scale(1.1);
      }
    }
  }

  .blog-item-description {
    padding-right: 10px;
    width: calc( 67% - 25px );
    padding-top: 0px;
    min-height: 260px;
    height:fit-content;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 26px;
      font-weight: 700;
      word-wrap: break-word;
      height:fit-content;
      color: var(--title-color);
      margin-bottom: 5px;
      transition-duration: .3s;

    }
    .title:hover {
      color: var(--text-color);
    }
    .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: var(--text-soft-color);
      margin-bottom: 16px;
    }
    .roundDateCat{
      display:flex;
      overflow: hidden;
      margin-bottom: 10px;

      .dateC{
        width: 159px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        letter-spacing: 0.04em;
        color: var(--text-color);
      }
      .category{
        width: calc(100% - 159px);
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--text-soft-color);
        align-items: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .roundDateCat.down{
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: flex-end;
      align-self: flex-end ;
      justify-self: flex-end ;
      width: 100%;

      span:hover,.readMore{
        color: var(--btn-color);
      }

      span:hover,
      .readMore:hover {
        filter: brightness(0.90);
        // filter: drop-shadow(0px 1px 3px #DFDFE8);
      }

    }
    .description {
      font-size: 16px;
      line-height: 24px;
      color: var(--text-color);

      span, .readMore {
        margin: 0;
        color: var(--btn-color);
      }
       span:hover,
      .readMore:hover {
        filter: brightness(0.90);
        // filter: drop-shadow(0px 1px 3px #DFDFE8);
      }
    }
     .status {
        margin: 0;
        color: var(--err-color);
      }
      .status:hover {
        filter: brightness(0.90);
        // filter: drop-shadow(0px 1px 3px #DFDFE8);
      }
  }
}
a:last-child .blog-item-wrapper {
  border-bottom: none;
  margin-bottom: 80px;
}
.blog-item-wrapper.last {
  border-bottom: none;
}
@media only screen and (max-width: 720px){
  .blog-item-wrapper {
    .aspect-wrapper {
      width: 50%;
      height: auto;
    }
    .blog-item-description {
      width: calc( 50% - 25px );
    }
  }
}
@media only screen and (max-width: 450px){
  .blog-item-wrapper {
    flex-direction: column;

    .aspect-wrapper {
      width: 100%;
      height: auto;
    }
    .blog-item-description {
      width: 100%;
    }
  }
}
