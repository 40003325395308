
            @import "@/assets/css/vars.scss";
          
































































































.aspect-wrapper {
  width: calc(33% - 15px);
  margin-bottom: 52px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  .aspWrap{
    overflow: hidden;
    border-radius: 5px;
    // background: #DEE0E5;
  }
  .aspect {
    padding-top: 75%;
    position: relative;
    margin-bottom: 10px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
     // -webkit-mask-image: -webkit-radial-gradient(white, black);
    will-change: transform;
    position: relative;
    z-index: 100;
  }
  span {
    color: var(--text-color);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 3px;
  }
  .dataWrapp {
   // margin-top: 3px; // +2px comment from figma
    margin-top: 5px;
    display: flex;
    .date{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.04em;
      white-space: nowrap;
      color: var(--text-color);
      margin-right: 20px;
    }
    .category{
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      // display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-soft-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;

    color: var(--title-color);
  }
  .title:hover {
    // color: #000000;
    // filter: brightness(5);
  }
}
.blog-ad-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  will-change: transform;

  img {
    z-index: 5;
    height: 100%;
    object-fit: cover;
    width: auto;
    border-radius: 5px;
    transition: 1s ease-in-out;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    -webkit-font-smoothing: antialised;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur(0);
  }
}
//// hover
.aspect-wrapper:hover {
  cursor: pointer;
  .aspect {
           -webkit-border-radius: 5px !important;
            -khtml-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            border-radius: 5px !important;
            -webkit-border-top-left-radius: 5px !important;
            -webkit-border-top-right-radius: 5px !important;
            -webkit-border-bottom-left-radius: 5px !important;
            -webkit-border-bottom-right-radius: 5px !important;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            will-change: transform;
            -webkit-transform: translateZ(0);
            overflow: hidden;

    .blog-ad-wrapper {
       border-radius: 5px !important;
       overflow: hidden;
      img {
        transform: scale(1.1);
      }
    }
  }
  .title {
    color: var(--title-color);
  }
}
@media only screen and (max-width: 768px){
  .aspect-wrapper {
    width: calc(50% - 15px);
    margin-bottom: 32px;
  }
}
@media only screen and (max-width: 450px){
  .aspect-wrapper {
    width: 100%;
  }
}
