
            @import "@/assets/css/vars.scss";
          





























































































































































.modalShare {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    background: #000000e1;
    // z-index:1000 !important;
    z-index: 211 !important;
    display: flex;
    justify-content: center;

    // align-items: center;
    .exit {
        // position: absolute;
        top: 0px;
        right: 0px;
        margin-top: 60px;
        margin-right: 59px;
        position: absolute;

        img {
            width: 22px;
        }
    }

    .contentShare {
        // background: rgba(231, 236, 237, 0.555);
        // padding-top: 190px; 
        margin-top: 100px;
        padding-top: 90px;
        width: 624px;
        min-height: 469px;

        // overflow: auto;
        .txt {
            text-align: center;
            // padding-bottom: 25px;
            // position: relative;
            // height: 230px;
            // padding-bottom: 60px;
            // height: initial;
            height: 230px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                text-align: center;
                font-style: normal;
                font-weight: normal;
                font-size: 80px;
                line-height: 80px;
                color: #FFFFFF;
                margin-top: 0px;
                margin-bottom: 35px;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 0;
                margin-bottom: 10px;
                margin-right: 30px;
                margin-left: 30px;
            }

            .link {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #C4C4C4;
                margin-top: 0;
                text-decoration: none;
                margin-right: 30px;
                margin-left: 30px;

                a {
                    color: #C4C4C4;
                    text-decoration: none;
                    cursor: grab;
                }

                a:active {
                    color: #C4C4C4;
                    text-decoration: none;
                    cursor: grabbing;
                }
            }

            .errormsg {
                width: 513px;
                max-width: 100%;
                height: 56px;
                background: #000000;
                /* text 80 */

                border: 1px solid #CFD8DB;
                box-sizing: border-box;

                border-radius: 2px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin: 0 auto;

                // margin-bottom: 30px;
                img {
                    margin-right: 10px;
                }

            }

        }

        .shareIcon {
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            justify-content: center;
            align-items: center;

            .spanImg {
                margin: 0 15px;
                width: 79px;
                text-decoration: none !important;
                text-align: center;
                // margin-bottom: 51px;
                margin-bottom: 33px; // only the space between twitter and icon below is different 32px

                .aLink {
                    height: 80px !important;
                    text-decoration: none;
                }

                .img {
                    background: #C4C4C4;
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;

                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                    color: #E7ECED;
                    text-decoration: none !important;
                    margin: 5px 0;
                }
            }
        }
    }
    .sBr {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .modalShare {
        .contentShare {
            // background: rgba(231, 236, 237, 0.555);
            // padding-top: 95px;
            padding-top: 25px;
            margin-top: 70px;
            box-sizing: border-box;
            width: 100%;
            height: initial;
            overflow: auto;
            .txt {
                // padding-bottom: 32px;
                height: 210px;
                .title {
                    font-size: 60px;
                    line-height: 80px;
                    color: #FFFFFF;
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
                .errormsg {
                    width: initial;
                    height: initial;
                    padding: 28px 33px;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: initial !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 0 auto;

                    // margin-bottom: 30px;
                    img {
                        height: fit-content;
                        margin-top: 6px;
                        margin-right: 10px;
                        margin-left: 10px;
                    }

                }

            }

        }

        .sBr {
            display: initial;
        }
    }

}

@media only screen and (max-width: 450px) {
    .modalShare {
        .exit {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-top: 40px;
            margin-right: 39px;

            img {
                width: 15px;
            }
        }
        .contentShare {
            // background: rgba(231, 236, 237, 0.555);
            // padding-top: 95px;
            padding-top: 25px;
            margin-top: 70px;
            box-sizing: border-box;
            width: 100%;
            height: initial;
            overflow: auto;
            .txt {
                text-align: center;
                //  padding-bottom: 0px;
                // height: initial;
                // min-height: 230px;
                .title {
                    font-size: 60px;
                    line-height: 80px;
                    color: #FFFFFF;
                    margin-top: 0px;
                    // margin-bottom: initial;
                }
                .description {
                    font-size: 15px;
                    line-height: 24px;
                    padding-top: 10px;
                    margin-top: 0;
                    margin-bottom: 10px;
                    margin-right: 30px;
                    margin-left: 30px;
                }
                .link {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    margin-top: 0;
                    text-decoration: none;
                    margin-right: 30px;
                    margin-left: 30px;
                    margin-bottom: 0px;

                    a {
                        color: #C4C4C4;
                        text-decoration: none;
                    }
                }
            }
            .shareIcon {
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                width: 80%;
                justify-content: center;
                align-items: center;

                .spanImg {
                    width: fit-content;
                    text-decoration: none !important;
                    text-align: center;
                    margin-bottom: 22px !important;
                    margin-left: 0;
                    margin-right: 15px;

                    .aLink {
                        width: 60px;
                        height: 60px;
                        text-decoration: none;
                    }

                    .img {
                        background: #C4C4C4;
                        width: 79px;
                        height: 79px;
                        border-radius: 50%;

                    }

                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 13px;
                        line-height: 24px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }

                .spanImg:nth-of-type(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 351px) {
    .modalShare {
        .contentShare {
            .shareIcon {
                .spanImg:nth-of-type(3n) {
                    margin-right: 15px;
                }
            }
        }
    }
}