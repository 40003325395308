
            @import "@/assets/css/vars.scss";
          


































































































































































































































@import '../assets/css/buttonStyle.scss';
.bacUpX{
  position: fixed;
  top:0px;
  left:-50px;
  width:30%;
  height: auto;
  // background-image: url("../assets/images/upBack.png");
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   // background-size: 100%  ;
  //   background-size: cover;
  //   background-position: center;
  img{
    width: 100%;
  }

}
.backDownX{
  z-index: 0;
  position: fixed;
  bottom :0px;
  right:0px;
  width:40%;
  height: auto;
  // background-image: url("../assets/images/downBack.png");
  //   background-repeat: no-repeat;
   
  //   background-attachment: fixed;
  //   // background-size: 100%  ;
  //   background-size: cover;
  //   background-position: center;
  img{
    width: 100%;
  }

}
.blog-list-wrapper {
  min-height: calc(100vh - 250px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 6px;
  box-sizing: border-box;
  padding-top: 100px;
  // text-align: cente;



  .content {
    display: flex;
   
  }
  .leftSide {
    position: relative;
    width:100%;
   
    .not_found_blog{
      // height: calc(100vh - 80px);
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapp{
        max-width: 100%;
        position: relative;
        img {
          max-width: 100%;
        }
        .text{
          font-weight: 700;
          font-size: 36px;
          text-align: center;
          color:var(--btn-color);
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }

  }
  .rightSide {
    // display: flex;
    padding-top: 10px;
    margin-left: auto;
    margin-left: 40px;
    position: sticky;
    top: 25px;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    background-color: var(--main-background-color);
  }
  .wrappTitle{
    width: 50%;
    margin-left: auto;
    margin-bottom: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    .line{
      height: 7px;
      background-color: var(--btn-color);
    }
  }

  h1 {
    margin: 0px;
    
    font-size: 40px;
    line-height: 46px;
    color: var(--text-color);
  }
  .show-more-wrapper {
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    // max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: var(--text-soft-color);
    }
  }
}
@media only screen and (max-width: 1350px){
  .blog-list-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    .wrappTitle{
      margin-top:60px;
      width: 80%;
    }

    h1 {
      font-size: 34px;
    }
  }
}
@media only screen and (max-width: 1080px) {
  .content {
    flex-direction: column;

  .leftSide {
    order: 2;

  }
  .rightSide {
    // top: 0px;
    position: initial;
    order: 1;
    width: 100%;
    margin-left: 0;
    z-index:100;
    }
  }
}

@media only screen and (max-width: 500px){
  .blog-list-wrapper {
    padding-top: 22px;
    .wrappTitle{
      width: 100%;
      margin: auto;
      margin-bottom: 35px;
      .line{
        height: 3px;
        background-color: var(--btn-color);
      }
    }

    h1 {
      font-size: 30px;
      // margin-bottom: 47px;
    }
  }
}
