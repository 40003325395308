.view-all-btn {
    cursor: pointer;
    min-width: 159px;
    height: 49px;
    left: 881px;
    top: 3293px;

    background: var(--btn-color);
    border-radius: 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--main-background-color);
}

.view-all-btn:hover {
    filter: brightness(0.90);
}


// login, signin, 
.buttonSend {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    // height: 50px;
    padding: 13px;
    font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 24px;
    background: var(--btn-color);
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--main-background-color);
    text-decoration: none;

}

.buttonCancel {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    // height: 50px;
    padding: 13px;
    font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 24px;
    // background: var(--btn-color);
    border: 1px solid var(--btn-color);
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--btn-color);
    text-decoration: none;

}


.buttonSend:hover {
    // background-color: var(--btn-hover);
    filter: brightness(0.90);
    // filter: drop-shadow(0px 1px 3px #DFDFE8);
}

.buttonCancel:hover {
    filter: drop-shadow(0px 1px 3px #DFDFE8);
}

.haveaccount {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: var(--text-soft-color);

}

.orange {
    font-style: normal;
    font-weight: normal;
    // font-size: 15px;
    // line-height: 25px;
    cursor: pointer;
    color: var(--btn-color);
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .buttonSend {
        // cursor: pointer;
        // width: 100%;
        // height: 50px;
        // font-size: 15px;
        // line-height: 24px;
    }
}